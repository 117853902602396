import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'next-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import { AnimateUpDown } from 'Components/Animate';
import Image from 'Components/Image';
import External from 'Assets/svg/external.svg';
import Chevron from 'Assets/svg/chevron.svg';
import s from './Nav.module.scss';

const Nav = ({
    className = 'Nav',
    items = [],
    label = '',
    modifier = '',
    currentId = null,
    parentIds = [],
    allowChildren = false,
    toggleChildren = false,
    hideLevel = 2,
    hasIcon = false,
    onClickHandler = () => {},
}) => {
    if(_.isEmpty(items)) {
        return null;
    }

    const classes = classNames(
        className,
        {[s[`${className}--${modifier}`]]: modifier},
    );
    return (
        <nav className={classes} aria-label={label}>
            <List
                className={className}
                items={items}
                currentId={currentId}
                parentIds={parentIds}
                allowChildren={allowChildren}
                toggleChildren={toggleChildren}
                hasIcon={hasIcon}
                onClickHandler={onClickHandler}
                hideLevel={hideLevel}
                title='Root'
            />
        </nav>
    );
};

Nav.propTypes = {
    className: PropTypes.string.isRequired,
    currentId: PropTypes.string,
    parentIds: PropTypes.array,
    items: PropTypes.array,
    label: PropTypes.string,
    modifier: PropTypes.string,
    allowChildren: PropTypes.bool,
    toggleChildren: PropTypes.bool,
    hasIcon: PropTypes.bool,
    onClickHandler: PropTypes.func,
    hideLevel: PropTypes.number,
};

const List = ({
    className = '',
    items = [],
    currentId = '',
    parentIds = [],
    isFirstLevel = true,
    hideLevel = 2,
    currentLevel = 0,
    allowChildren = false,
    toggleChildren = false,
    hasIcon = false,
    onClickHandler = () => {},
}) => {
    if(_.isEmpty(items)) {
        return null;
    }

    // Possibility to define from which level should be hidden, this is unrelated to toggleChildren
    const hide = currentLevel >= hideLevel && hideLevel >= 0 && !toggleChildren;

    const classes = classNames(
        s[`${className}__List`],
        {[s[`${className}__List--Children`]]: !isFirstLevel},
        {[s[`${className}__List--ToggleChildren`]]: allowChildren && toggleChildren},
        {[s[`${className}__List--Hide`]]: hide},
    );

    return (
        <ul className={classes}>
            {items.map((item, index) => (
                <Item
                    item={item}
                    key={index}
                    className={className}
                    currentId={currentId}
                    parentIds={parentIds}
                    isFirstLevel={isFirstLevel}
                    hideLevel={hideLevel}
                    currentLevel={currentLevel}
                    allowChildren={allowChildren}
                    toggleChildren={toggleChildren}
                    hasIcon={hasIcon}
                    onClickHandler={onClickHandler}
                />
            ))}
        </ul>
    );
};

List.propTypes = {
    className: PropTypes.string.isRequired,
    items: PropTypes.array,
    currentId: PropTypes.string,
    parentIds: PropTypes.array,
    isFirstLevel: PropTypes.bool,
    hideLevel: PropTypes.number,
    currentLevel: PropTypes.number,
    allowChildren: PropTypes.bool,
    toggleChildren: PropTypes.bool,
    hasIcon: PropTypes.bool,
    onClickHandler: PropTypes.func,
};

const Item = ({
    item = {},
    className = 'Nav',
    currentId = null,
    parentIds = [],
    allowChildren = false,
    toggleChildren = false,
    hideLevel = 2,
    currentLevel = 0,
    hasIcon = false,
    onClickHandler = () => {},
}) => {
    const {t} = useTranslation();

    const listRef = useRef();

    const children = _.get(item, 'children', []);
    const forceExpanded = _.get(item, 'forceExpanded', false);
    const hasChildren = (allowChildren || forceExpanded) && !_.isEmpty(children);

    const flat = (child) => {
        return child.children ? [child.id, ...child.children.map(flat)] : child.id;
    };
    const itemId = _.get(item, 'id', '');
    const itemTitle = _.get(item, 'title', '');
    const allSubIds = _.flattenDeep([itemId, ...children.map(flat)]);

    // For activating toggling of all children, pass this:
    // {allowChildren: true, toggleChildren: true, hideLevel: -1}

    // Only used if toggle should be used, the children should be hidden/visible with animation
    const defaultExpanded = toggleChildren && (allSubIds.includes(currentId) || currentId === itemId);
    const [isExpanded, setIsExpanded] = useState(defaultExpanded);

    const toggleText = isExpanded ?
        t('nav.hideChildren', {title: itemTitle}) :
        t('nav.showChildren', {title: itemTitle});

    const classes = classNames(
        s[`${className}__Item`],
        {[s[`${className}__Item--Expanded`]]: isExpanded || forceExpanded},
    );

    return (
        <li className={classes}>
            <Link
                hasIcon={hasIcon}
                {...item}
                className={className}
                currentId={currentId}
                currentParentIds={parentIds}
                currentChildIds={allSubIds}
                onClickHandler={(e) => {
                    if(onClickHandler === null) {
                        const itemClickHandler = _.get(item, 'onClickHandler', null);
                        if(itemClickHandler !== null) {
                            itemClickHandler(e, item);
                        }
                    } else {
                        onClickHandler(e, item);
                    }
                }}
            />

            {hasChildren && toggleChildren &&
                <button
                    type="button"
                    className={s[`${className}__Toggle`]}
                    onClick={() => setIsExpanded(!isExpanded)}
                >
                    <span className="sr-only">{toggleText}</span>
                    <Chevron />
                </button>
            }
            {hasChildren && (toggleChildren || forceExpanded) &&
                <AnimateUpDown
                    defaultVisible={defaultExpanded}
                    isVisible={isExpanded || forceExpanded}
                    childRef={listRef}
                >
                    <div className={s[`${className}__Children`]} ref={listRef}>
                        <List
                            className={className}
                            items={children}
                            currentId={currentId}
                            parentIds={parentIds}
                            isFirstLevel={false}
                            hideLevel={hideLevel}
                            currentLevel={currentLevel+1}
                            allowChildren={allowChildren}
                            toggleChildren={toggleChildren}
                            hasIcon={hasIcon}
                            onClickHandler={onClickHandler}
                        />
                    </div>
                </AnimateUpDown>
            }

            {hasChildren && !toggleChildren && !forceExpanded &&
                <List
                    className={className}
                    items={children}
                    currentId={currentId}
                    parentIds={parentIds}
                    isFirstLevel={false}
                    hideLevel={hideLevel}
                    currentLevel={currentLevel+1}
                    allowChildren={allowChildren}
                    toggleChildren={toggleChildren}
                    hasIcon={hasIcon}
                    onClickHandler={onClickHandler}
                />
            }
        </li>
    );
};

Item.propTypes = {
    item: PropTypes.object,
    className: PropTypes.string.isRequired,
    currentId: PropTypes.string,
    parentIds: PropTypes.array,
    allowChildren: PropTypes.bool,
    toggleChildren: PropTypes.bool,
    hideLevel: PropTypes.number,
    currentLevel: PropTypes.number,
    hasIcon: PropTypes.bool,
    onClickHandler: PropTypes.func,
};

const Link = ({
    className = 'Nav',
    id = '',
    currentId = null,
    currentChildIds = [],
    currentParentIds = [],
    title = '',
    description = '',
    text = '',
    url = '',
    target = null,
    rel = null,
    attrTitle = null,
    extraClasses = [],
    image = null,
    isLarge = false,
    hasIcon = false,
    onlyIcon = false,
    Icon = null,
    onClickHandler = () => {},
}) => {
    const sizes = [
        '(min-width: 1440px) 311',
        '25vw',
    ];

    const titleClass = classNames(
        {[s[`${className}__LinkText`]]: !hasIcon && !onlyIcon},
        {'sr-only': hasIcon || onlyIcon},
    );
    const isCurrent = !_.isEmpty(currentId) && id === currentId;

    // Check if item is a parent of current id in menu, or is a parent page of current page
    const isParentOfChildren = !_.isEmpty(currentChildIds) && currentChildIds.includes(currentId);
    const isParentOfCurrent = !_.isEmpty(currentParentIds) && currentParentIds.includes(id);
    const isParent = !isCurrent && (isParentOfChildren || isParentOfCurrent);

    const classes = classNames(
        s[`${className}__Link`],
        {[s[`${className}__Link--NoLink`]]: _.isEmpty(url)},
        {[s[`${className}__Link--HasIcon`]]: hasIcon || Icon},
        {[s[`${className}__Link--OnlyIcon`]]: onlyIcon},
        {[s[`${className}__Link--HasImage`]]: !_.isEmpty(image)},
        {[s[`${className}__Link--Current`]]: isCurrent},
        {[s[`${className}__Link--Parent`]]: isParent},
        {[s[`${className}__Link--Large`]]: isLarge},
        extraClasses.map((name) => [s[`${className}__Link--${_.upperFirst(name)}`]]),
    );
    if(_.isEmpty(url)) {
        return (
            <div className={classes}>
                <h2 className={titleClass}>
                    <span dangerouslySetInnerHTML={{__html: title}} />
                </h2>

                {!_.isEmpty(description) &&
                    <span
                        className={s[`${className}__Description`]}

                        dangerouslySetInnerHTML={{__html: description}}
                    />
                }

                {!_.isEmpty(text) &&
                    <div
                        className={s[`${className}__Text`]}

                        dangerouslySetInnerHTML={{__html: text}}
                    />
                }
            </div>
        );
    }
    return (
        <a
            className={classes}
            href={url}
            target={target}
            rel={rel}
            title={attrTitle}
            onClick={(e) => onClickHandler(e)}
        >
            {isLarge && !_.isEmpty(image) &&
                <div className={s[`${className}__Image`]}
>
                    <Image {...image} sizes={sizes} />
                </div>
            }

            {isLarge && _.isEmpty(image) &&
                <div className={s[`${className}__Shell`]}
 />
            }

            <span className={titleClass}>
                <span dangerouslySetInnerHTML={{__html: title}} />
                <span className={s[`${className}__External`]}
>
                    <External />
                </span>
            </span>

            {!_.isEmpty(description) &&
                <span
                    className={s[`${className}__Description`]}

                    dangerouslySetInnerHTML={{__html: description}}
                />
            }

            {!_.isEmpty(text) &&
                <div
                    className={s[`${className}__Text`]}

                    dangerouslySetInnerHTML={{__html: text}}
                />
            }

            {Icon &&
                <Icon />
            }
        </a>
    );
};

Link.propTypes = {
    className: PropTypes.string.isRequired,
    id: PropTypes.string,
    itemId: PropTypes.number.isRequired,
    currentId: PropTypes.string,
    currentParentIds: PropTypes.array,
    currentChildIds: PropTypes.array,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    text: PropTypes.string,
    url: PropTypes.string.isRequired,
    target: PropTypes.string,
    rel: PropTypes.string,
    attrTitle: PropTypes.string,
    extraClasses: PropTypes.array,
    image: PropTypes.object,
    isLarge: PropTypes.bool,
    hasIcon: PropTypes.bool,
    onlyIcon: PropTypes.bool,
    Icon: PropTypes.elementType,
    onClickHandler: PropTypes.func,
};

export default Nav;
